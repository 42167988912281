<template>
  <div class="home">
    <div class="live-container pa-5">
      <figure class="image" v-if="!isStarted && !isEnded">
        <img :src="require('@/assets/live/standby.png')" />
      </figure>
      <figure class="image" v-show="isEnded">
        <img :src="require('@/assets/live/live-end.png')" />
      </figure>
      <div v-if="isStarted && !isEnded" :key="lang">
        <video
          :id="vIdEn"
          class="video-js vjs-fluid vjs-default-skin live-vdo"
          controls
          preload="auto"
          data-setup="{}"
        >
          <source :src="liveSource" type="application/x-mpegURL" />
        </video>
      </div>

      <!-- <div v-if="isStarted && !isEnded && lang == 'ch'">
        <video
          :id="vIdCh"
          class="video-js vjs-fluid vjs-default-skin live-vdo"
          controls
          preload="auto"
          data-setup="{}"
        >
          <source
            src="https://cdn3.wowza.com/1/cW5EYjBuY05TRGFo/TWxHUVNp/hls/live/playlist.m3u8"
            type="application/x-mpegURL"
          />
        </video>
      </div> -->

      <div class="live-actions m-2">
        <b-button rounded type="is-primary" @click="en()"
          ><strong>EN</strong></b-button
        >
        <b-button rounded type="is-primary" class="ml-2" @click="ch()"
          ><strong>中文</strong></b-button
        >
      </div>
    </div>
    <div class="bottom-content">
      <div class="is-flex-desktop is-block-mobile">
        <div class="is-flex-direction-column">
          <router-link to="/catalog">
            <figure class="image">
              <img :src="require('@/assets/landing/p2/catalog.png')" />
            </figure>
          </router-link>
        </div>
        <div class="package">
          <router-link to="/promotion">
            <figure class="image">
              <img :src="require('@/assets/landing/p2/package.png')" />
            </figure>
          </router-link>
        </div>
      </div>
      <div class="delete-mt">
        <div class="columns is-gapless">
          <div class="col pull-down">
            <router-link class="ma" to="/to_virtual">
              <div class="box">
                <article class="media">
                  <figure class="image">
                    <img :src="require('@/assets/live/virtual.png')" />
                  </figure>
                </article>
              </div>
            </router-link>
          </div>
          <div class="col col-center">
            <div class="box two">
              <article class="media">
                <figure class="image">
                  <video
                    controls
                    :src="'/videos/product-preview.mp4'"
                    :poster="require('@/assets/live/product-clip-thumb.png')"
                  ></video>
                </figure>
              </article>
            </div>
          </div>
          <div class="col pull-down">
            <router-link class="ma" to="to_survey">
              <div class="box three">
                <article class="media">
                  <figure class="image">
                    <img :src="require('@/assets/live/survey.png')" />
                  </figure>
                </article>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Live",
  components: {
    // Carousel,
  },
  data() {
    const windowSize = screen.width;
    const isMobile = windowSize < 769;
    return {
      windowSize,
      isMobile,
      playing: false,
      fetchLive: null,
      failToPlay: false,
      lang: "en",
      liveSource:
        "https://cdn3.wowza.com/1/YTN5QncrU3Y5c3Qz/M1dRN1lM/hls/live/playlist.m3u8",
      vIdEn: "vEn1",
      vIdCh: "vCh1",
    };
  },
  methods: {
    playLiveVideo() {
      let elId = this.vIdEn;
      const player = window.videojs(elId);
      player.play();
    },
    en() {
      // window.location = "/live/en";
      this.lang = "en";
      this.liveSource =
        "https://cdn3.wowza.com/1/YTN5QncrU3Y5c3Qz/M1dRN1lM/hls/live/playlist.m3u8";

      localStorage.setItem("lang", "en");
    },
    ch() {
      // window.location = "/live/ch";
      this.lang = "ch";
      this.liveSource =
        "https://cdn3.wowza.com/1/cW5EYjBuY05TRGFo/TWxHUVNp/hls/live/playlist.m3u8";

      localStorage.setItem("lang", "ch");
    },
  },
  computed: {
    ...mapState({
      isStarted: "liveStart",
      isEnded: "liveEnd",
      currentTime: "liveCurrentTime",
    }),
  },
  watch: {
    isMobile(newWidth) {
      this.isMobile = newWidth < 769;
      this.windowSize = newWidth;
    },
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.lang == "en";
      this.en();
    } else {
      if (lang == "ch") {
        this.lang == "ch";
        this.ch();
      }
    }
  },
  async mounted() {
    this.fetchLive = setInterval(async () => {
      await this.$store.dispatch("getLive");

      if (this.isStarted) {
        if (this.playing === false) {
          this.playLiveVideo();
          // if (this.currentTime > 0) vdo.currentTime = this.currentTime;
          // try {
          //   await vdo.play();
          // } catch {
          //   this.failToPlay = true;
          //   if (confirm("Press a Play button!")) {
          //     vdo.play();
          //   }
          // }
          // vdo.autoPlay = true;
          // this.vIdEn = "vEn2";
          // this.vIdCh = "vCh2";
          this.playing = true;
          // clearInterval(this.fetchLive);
        }
      }
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.fetchLive);
  },
};
</script>
<style lang="scss">
.ma {
  margin: auto;
}
.live-actions {
  float: right;
}
.live-vdo {
  width: 100%;
  min-height: 500px;

  @media screen and (max-width: 768px) {
    min-height: 400px;
  }

  @media screen and (max-width: 426px) {
    min-height: 250px;
  }
}
.delete-mt {
  .col-center {
    width: 100%;

    @media screen and (max-width: 2560px) {
      width: 65%;
    }
    @media screen and (max-width: 1440px) {
      width: 69%;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .box {
    width: 90%;
  }

  .two {
    margin: auto;
    width: 100%;
  }

  .three {
    float: right;
    img {
      @media screen and (max-width: 768px) {
        padding-bottom: 80px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: -90px;
    .box {
      width: 100%;
    }
  }
}

.pull-down {
  display: flex;
  align-items: flex-end;
}
.home {
  margin-bottom: -100px;
}

.live-container {
  min-height: 30%;
  @media screen and (min-width: 769px) {
    padding: 4rem;
  }
}
.bottom-content {
  background-image: url("./../assets/landing/p2/foot-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (min-width: 769px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 769px) {
    margin-top: 60px;
  }

  .package img {
    @media screen and (max-width: 426px) {
      padding-bottom: 85px;
    }

    @media screen and (max-width: 769px) {
      padding-bottom: 90px;
    }
  }
}
.lazboy-conf-title {
  font-family: "Helvetica Neue LT W05 65 Medium";
  color: #0069bf;
}
</style>
